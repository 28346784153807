import React from "react"
import classNames from "classnames"

import MobileWindow from "./MobileWindow"
import avatarImage from "../../../static/images/avatar.png"
import { LanguageProficiency } from "../Windows/utils/languageProficiency"

import * as styles from "./utils/mobilePages.module.scss"

const AboutPage = () => {
    return (
        <MobileWindow title="about">
            <div className="p-5 has-text-centered">
                    <div className={classNames("image is-128x128 is-inline-block")}>
                        <img
                            src={avatarImage}
                            alt="avatar"
                            className="is-rounded"
                        />
                    </div>
                    <p className={classNames(styles["about__headerName"])}>
                        Sharlene Yap
                    </p>
                    <p className="has-text-primary">葉千林</p>
                    <p className="is-size-6">
                        PH-based freelance illustrator, animator<br/>
                        Former web developer at <a href="https://medgrocer.com/" target="_blank" rel="noopener noreferrer">MedGrocer</a>
                    </p>
                </div>
                <hr className="my-0"/>
                <div className={classNames("px-5", styles["about__content"])}>
                    <br/>
                    <div className="is-size-6 py-4">
                        <div className="pb-3">hi! i'm shar, an animator and web developer. i...</div>
                        <div className="content">
                            <ul className="mt-2">
                                <li>create <a href="https://www.youtube.com/playlist?list=PL1xe7Vhuzrm-oEZUi0DO2Wu1pgUzMDfHN/" target="_blank" rel="noopener noreferrer">hand-drawn animations</a>,</li>
                                <li>create <a href="https://www.youtube.com/playlist?list=PL1xe7Vhuzrm8RwrchXW-o08u9Tq8gjzsW" target="_blank" rel="noopener noreferrer">videos</a> talking about things i like,</li>
                                <li>create website wireframes, and</li>
                                <li>do frontend web development!</li>
                            </ul>
                        </div>

                        <div className="pb-3">interested in working with me? send me an email at <a href="mailto:hi@sharyap.com" rel="noopener noreferrer">hi@sharyap.com</a>! :)</div>

                    </div>
                    
                    <hr/>
                    <span className={classNames(styles["about__contentHeader"])}>
                        EDUCATION
                    </span>
                    <br/>
                    <div className={classNames(styles["about__contentBody"])}>
                            Bachelor of Science in Computer Science
                        <br/>
                        <span className="is-size-7">
                            (GRADUATED CUM LAUDE 2023)
                        </span>
                    </div>

                    <hr/>
                    <span className={classNames(styles["about__contentHeader"])}>
                        OTHER INTERESTS
                    </span>
                    <br/>
                    <p className="content">
                        <ul className="mt-2">
                            <li>Game development</li>
                            <li>Music! Singing, guitar, piano, ocarina...</li>
                            <li>Legend of Zelda franchise</li>
                            <li>HoYoVerse Games (Genshin Impact, Tears of Themis, etc.)</li>
                            <li>Indie games with forest themes :)</li>
                        </ul>
                    </p>
                    <hr/>
                    <LanguageProficiency styles={styles}/>
                    <br/><br/>
                </div>
        </MobileWindow>
    )
}
export default AboutPage